.tabs-panel {
	position: absolute;
	top: -41px;
	left: 0px;
}

.organization-information, .login-account {
	border-radius: unset;
	box-shadow: unset;
	border-color: #004E7B !important;
	padding: 6px 25px;
	height: 40px;
}

.training-facilities {
	border-radius: unset;
	box-shadow: unset;
	border-color: #004E7B;
	padding: 6px 25px;
	height: 40px;
}

.organization-information {
	border-top-left-radius: 16px;

}

.training-facilities {
	border-top-right-radius: 16px;
}

.organization-information.active, .training-facilities.active, .login-account.active {
	background-color: #004E7B !important;
	color: #fff !important;
}

.organization-information.active span, .training-facilities.active span, .login-account.active span {
	color: #fff;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-btn-default.organization-information:not(:disabled):hover,
:where(.css-dev-only-do-not-override-12jzuas).ant-btn-default.training-facilitie:not(:disabled):hover,
:where(.css-dev-only-do-not-override-12jzuas).ant-btn-default.login-account:not(:disabled):hover {
	border-color: unset
}