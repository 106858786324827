@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  color: #202027;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #202027;
  font-size: 15px;
}

option {
  color: #202027;
}

.text-18 {
  font-weight: 500;
  font-size: 18px;
  color: #202027
}

.text-12 {
  font-weight: 500;
  font-size: 12px;
  color: #9C9C9C;
  line-height: 1;
}

.active-color {
  color: #fff;
}

.inactive-color {
  color: #202027;
}

.custom-input {
  min-width: 150px;
  height: 38px;
  border: 1px solid #d9d9d9;
  /* border-radius: 10px; */
  background-color: #FFFFFF;

  color: #202027;
  font-size: 15px;
  /* font-weight: 500; */
}

.dropdown {
  padding-left: 8px;
  padding-right: 8px;
}

.custom-button {
  justify-content: center;
  align-items: center;
  /* padding: 10px 16px; */
  height: 38px;
  /* border-radius: 10px; */
}

.custom-button span {
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.button-primary {
  background-color: #045993 !important;
}

.button-danger {
  background: #FFEEEE !important;
  border: 1px solid #FFA6A6 !important;
}

.button-danger span {
  color: #202027 !important;
}


.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 250px;
  height: 36px;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px;
  /* background-color: transparent !important; */
  background-color: #Fff !important;
  /* border: none !important; */
}

.rouder-8 .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* border-radius: 8px !important; */
}

/* .ant-select-selection-item {
  height: 38px;
  line-height: 2.7;
} */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px;
}

/* .ant-input-affix-wrapper>input.ant-input {
  background-color: #F5F5F5 !important;
} */

.btn-logout {
  font-weight: 700;
  font-size: 16px;
  color: #FF2323 !important;
  text-align: left !important;
}

.w-330 {
  width: 330px !important;
}

.w-330 .ant-select-selector {
  width: 327px !important;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #F5F5F5 !important;
  color: fieldtext !important;
}

.button-dash {
  background: #A7A7A7;
}

.button-dash span {
  color: #fff;
}

.w-200 {
  width: 200px;
}

input::placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
}

.ant-select-selection-search-input::placeholder {
  color: #202027 !important;
  font-size: 14px !important;
}

.ant-select-selection-placeholder {
  color: #999 !important;
  font-size: 14px !important;
}

.ant-table-wrapper .ant-table {
  background-color: transparent;
}

.ant-pagination .ant-pagination-item {
  border-radius: 6px;
}


.button-admin {
  background-color: #045993 !important;
  border-color: #045993 !important;
}

.button-osa {
  background-color: #E78C21 !important;
  border-color: #E78C21 !important;
}

.button-csdt {
  background-color: #1B8E5F !important;
  border-color: #1B8E5F !important;
}

.text-breadcrumb-admin {
  color: #045993 !important;
}

.text-breadcrumb-osa {
  color: #E78C21 !important;
}

.text-breadcrumb-csdt {
  color: #1B8E5F !important;
}

.pagination-admin .ant-pagination-item-active {
  border-color: #045993 !important;
  background-color: #045993 !important;
}

.pagination-admin .ant-pagination-item-active a {
  color: #fff !important;
}

.pagination-csdt .ant-pagination-item-active a {
  color: #fff !important;
}

.pagination-osa .ant-pagination-item-active a {
  color: #fff !important;
}

.pagination-csdt .ant-pagination-item-active {
  border-color: #1B8E5F !important;
  background-color: #1B8E5F !important;
}

.pagination-osa .ant-pagination-item-active {
  border-color: #E78C21 !important;
  background-color: #E78C21 !important;
}

.chakra-button.css-149pbde {
  font-size: 14px;
  font-weight: 500;
}

.ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-thead>tr>td {
  border-top: 1px solid #f0f0f0 !important;
}

.fill-color path {
  fill: #FF2323 ;
  /* color: ; */
}

.w-custom, .w-custom-1 {
  width: auto !important;
}

.w-custom-1 .ant-select-selector {
  width: 320px !important;
}

.w-custom .ant-select-selector {
  width: 327px !important;
}

.w-full {
  width: 100% !important;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-header {
  padding: 16px !important;
}

.ant-modal .ant-modal-content {
  border-radius: 16px;
  padding: 0 !important;
}

.ant-modal-close {
  color: #FF4B4B !important;
  font-weight: 500;
  font-size: 18px;
}

.ant-modal-title {
  font-weight: 500;
  font-size: 18px;
  color: #4A4A4A;
}

.ant-modal-body {
  /* padding-top: 16px; */
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-modal .ant-modal-header {
  border-radius: 16px;
}

.dashed-button {
  border-color: #00AEEF !important;
  height: 52px;
  /* border-radius: 10px; */
  margin: auto;
  margin-bottom: 15px;
  width: 75%;
}

.custom-input.w-full .ant-select-selector {
  width: 100%;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-btn-default.button-admin:disabled {
  opacity: .3 !important;
  background-color: #045993 !important;
  border-color: #045993 !important;
}

.ant-btn-default.button-csdt:disabled {
  opacity: .3 !important;
  border-color: #1B8E5F !important;
  background-color: #1B8E5F !important;

}

.ant-btn-default.button-osa:disabled {
  opacity: .3 !important;
  border-color: #E78C21 !important;
  background-color: #E78C21 !important;

}

.w-full .ant-select-selector {
  width: 100% !important;
}

.btn-F3C262.ant-btn-default:not(:disabled):hover {
  background: #F3C262 !important;
  border-color: #F3C262 !important;
}

.btn-FF4B4B.ant-btn-default:not(:disabled):hover {
  background: #FF4B4B !important;
  border-color: #FF4B4B !important;
}

.btn-F3C262 {
  background: #F3C262 !important;
  border-color: #F3C262 !important;
}

.btn-FF4B4B {
  background: #FF4B4B !important;
  border-color: #FF4B4B !important;
}

.ant-radio-wrapper span.ant-radio+* {
  font-size: 14px;
  font-weight: 400;
}

.btn-F3C262, .btn-FF4B4B {
  width: 100px;
}

.text-bold {
  /* font-weight: 500; */
  font-size: 15px;
  color: #202027;
}

.hidden {
  visibility: hidden;
  display: none;
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}

.ant-table-row.ant-table-row-level-1 .ant-table-cell.ant-table-cell-with-append .anticon-caret-right {
  visibility: hidden;
}

/* .hidden-icon-search:hover {
  visibility: visible
} */

.hidden-icon-search {
  visibility: visible !important;
}

.ant-table-filter-column {
  cursor: pointer;
}

.ant-table-filter-column .ant-table-filter-trigger .anticon.anticon-filter {
  visibility: hidden;
}

.ant-table-filter-column:hover .ant-table-filter-trigger .anticon.anticon-filter {
  visibility: visible
}

.ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-thead>tr>td {
  background: transparent;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: none !important;
}

.ant-table-tbody tr {
  background-color: #ffffff ;
}

.ant-table-tbody tr:nth-child(even) {
  background-color: #fcfcfc;
}

.my-table {
  width: 100%;
}

.my-table thead {
  border-bottom: 1px solid #F5F5F5;
  /* border-top: 1px solid #F5F5F5; */
}

.my-table thead p {
  text-align: left;
  padding: 12px;
}

.my-table tbody tr {
  border-bottom: 1px solid #F5F5F5;
}

.my-table tbody td {
  padding-left: 12px;
}

.box-shadow {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  /* width: 90%; */
}

.w-90 {
  width: 97% !important;
}

.max-w-1078 {
  margin-left: auto;
}

table.w-90.max-w-1078 td, table.w-90.max-w-1078 th {
  border: 1px solid #F5F5F5;
  /* display: flex;
  align-items: center; */
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.none-border {
  border: none !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 1px solid #045993 !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  border: 1px solid #045993;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add {
  border: 1px solid #045993;
}

.ant-tabs>.ant-tabs-nav {
  padding-left: 25px;
}

.ant-tabs-top>.ant-tabs-nav {
  margin: 0;
}

.ant-tabs-content-holder {
  border: 1px solid #045993 !important;
  border-top: none !important;
  /* border-radius: 10px; */
  padding: 15px;
}

.m-0 {
  margin: 0;
}

.text-align {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: auto;
}

.ant-input-number {
  width: 50px;
  height: 38px;
  /* border-radius: 20px; */
  border: 1px solid #d9d9d9;
}

.h-34 .ant-input-number-input {
  height: 34px !important;
}

.ant-input-number-input {
  height: 34px !important;
}

.h-34 {
  height: 34px !important;
}

.h-34 .ant-select-selector {
  height: 32px !important;
}

.ag-mini-filter {
  display: none !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 10px
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 2px !important;
}

.datepicker-filter-popper {
  z-index: 10 !important;
}

.ant-btn-default.ant-btn-dangerous {
  background: #FEE !important;
}

.ant-btn-default.ant-btn-dangerous span {
  color: #202027;
}

.rouder-8 {
  /* border-radius: 8px; */
}

.custom-input.rouder-8.w-full .ant-select-selection-placeholder {
  font-weight: 400;
  /* font-size: 12px !important; */
  color: #A0A6B1 !important;
}

.text-bold-1 {
  font-weight: 400;
  font-size: 12px;
  color: #A0A6B1;
}

.hidden-star {
  display: none;
  margin-left: -40px;
}


.toolTip {
  display: inline-flex;
  padding: 7px 11px 7px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #FFF;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
  left: -46px;
  top: -42px;
  visibility: hidden;
}

.IconStar:hover~.toolTip {
  visibility: visible;
}

.IconStar {
  position: relative;
  top: 14px;
}

.IconPolygon {
  top: 32px;
}

.tr-tid:hover .hidden-star {
  display: block;
}

.tr-tid:hover {
  background: #F5F5F5;
  cursor: pointer;
}

.tr-tid:hover .text-position {
  top: -40px
}

.text-position {
  top: -15px
}

.show-star {
  display: block;
  margin-left: -40px;
}

.top-up-40 {
  top: -40px
}

.mw-250 {
  min-width: 250px;
}

.mw-335 {
  min-width: 345px;
}

.mw-125 {
  min-width: 125px;
}

.mw-100 {
  min-width: 100px;
}

.d-flex {
  display: flex;
}

.checkbox-tidstar {
  position: absolute;
  left: -40px;
  top: 15%;
}

.ant-select {
  border-radius: 6px;
}

.ag-cell {
  padding-left: calc(var(--ag-cell-horizontal-padding) - 9px) !important;
}

.ant-table-thead {
  background: #f9f9f9;
}

input:focus-visible {
  outline: 1px solid #4096ff;
}

.mb-0 {
  margin-bottom: 0;
}

.ant-select-disabled .ant-select-selector {
  background: #f2f5f6 !important;
}

.ant-input-affix-wrapper-disabled {
  background: #f2f5f6 !important;
}

.ant-select-disabled .ant-select-selector .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25) !important;
  opacity: 1;
}

/* .ant-select-selector input {
  color: rgba(0, 0, 0, 0.25) !important;
  opacity: 1;
} */

.ant-select-disabled .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
  opacity: 1;
}

.triangle path {
  color: #1B8E5F;
}

@media screen and (min-width: 1200px) {
  .custom-nav {
    padding-inline-start: 8px !important;
  }
}

.ant-popover-inner {
  padding: 0 24px !important;
}

.not-button {
  min-width: 145px;
}

.not-button .chakra-text {
  text-align: left !important;
}

.mt-3 {
  margin-top: 1rem;
}

.ml-50 {
  margin-left: 50px;
}

.text-white, .text-white span {
  color: #fff;
}

.anticon.anticon-camera path {
  color: #fff;
}

.icon-search.chakra-icon path {
  color: #fff;
  font-size: 15px;
}

.ChevronLeftIcon path {
  color: #1B8E5F;
}

.color-FFA6A6 {
  background-color: #FFA6A6 !important;
}

.color-FFA6A6.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #FFA6A6 !important;
}

.color-FFA6A6.custom-button {
  border: #FFA6A6 !important;
}

.ant-modal .ant-modal-header {
  margin-bottom: 0px !important;
}

.w-123 {
  width: 123.56px !important;
}

.borderColor-F3C26 {
  border-color: #F3C262 !important;
}

.w-150 {
  width: 120px;
}

.w-150.custom-input {
  width: 120px !important;
}

.w-150 .ant-select-selector {
  width: 147px !important;
}

.mr-2 {
  margin-right: 1rem;
}

.w-full-1 .custom-input {
  width: 300px !important;
}

.w-full-1 .ant-select-selector {
  width: 355px !important;
}


.bg-045993, .bg-045993:hover {
  background-color: #045993 !important;
}

.ant-modal-header {
  padding: 16px;
}

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
}

.w-200 {
  width: 200px;
}

.w-200 .ant-select-selection-search-input {
  width: 380px !important;
}

.w-200 .ant-select-selector {
  /* width: 100%; */
  width: 390px !important;
}

.min-width-200 {
  min-width: 200px !important;
}