.tabs-panel {
	position: absolute;
	top: -41px;
	left: 0px;
}

.organization-information, .training-facilities {
	border-radius: unset;
	box-shadow: unset;
	border-color: #004E7B;
	padding: 6px 25px;
	height: 40px;
}

.organization-information {
	border-top-left-radius: 6px;

}

.training-facilities {
	border-top-right-radius: 6px;
}

.organization-information.active, .training-facilities.active {
	background-color: #004E7B;
	color: #fff;
}

.organization-information.active span, .training-facilities.active span {
	color: #fff;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-btn-default.organization-information:not(:disabled):hover, :where(.css-dev-only-do-not-override-12jzuas).ant-btn-default.training-facilitie:not(:disabled):hover {
	border-color: unset
}