.pl-3 {
  padding-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.5rem;
}

.mr-3 {
  margin-right: 0.5rem;
}

.button-danger {
  border-color: #ff4d4f !important;
  background-color: transparent;
}

.button-danger span {
  color: #ff4d4f !important;
}

.button-info {
  border-color: #d99000 !important;
  background-color: transparent;
}

.button-info span {
  color: #d99000 !important;
}

.py-2 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.ant-popconfirm .ant-popover-inner {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.ant-popconfirm .ant-btn-primary span {
  color: #fff;
}